import { useUserStore } from '@/stores/user.js';
import { useTeamStore } from '@/stores/team.js';
import { computed } from 'vue';

export const useAccountStatus = () => {
    const userStore = useUserStore();
    const teamStore = useTeamStore();

    const hasFullAccess = computed(() => {
        return (
            userStore.isVerified &&
            teamStore.hasRequiredInformation &&
            teamStore.isVerified &&
            (teamStore.enforces2FA ? userStore.has2FA : true)
        );
    });

    const shouldEnforceTFA = computed(() => {
        return teamStore.enforces2FA && !userStore.has2FA;
    });

    return {
        hasFullAccess,
        shouldEnforceTFA,
    };
};
