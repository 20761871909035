// handle response from server
export class Response {
    constructor(response) {
        this.data = response.data.data;
        this.relationships = response.data.data.relationships;
        this.included = response.data.included;
    }

    getData() {
        return this.data;
    }

    getRelations(type = null) {
        const relationships = this.relationships;

        if (type) {
            return relationships.filter((relationship) => {
                return relationship.data.type === type;
            });
        }

        return relationships;
    }

    getRelation(name = null) {
        return this.relationships[name];
    }

    getObjects(type = null) {
        const included = this.included;

        if (type) {
            return included.filter((include) => {
                return include.type === type;
            });
        }

        return included;
    }

    getObject(id) {
        const included = this.included;

        return included.find((include) => {
            return include.id === id;
        });
    }
}
