<script setup>
import { XMarkIcon } from '@heroicons/vue/20/solid';

defineProps({
    file: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['remove']);
</script>

<template>
    <div class="flex items-center justify-between gap-4 py-4 pr-4">
        <span>{{ file.name }}</span>
        <button
            type="button"
            @click="emit('remove')"
        >
            <XMarkIcon class="h-4 w-4 text-red-600" />
        </button>
    </div>
</template>
