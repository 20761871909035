<template>
    <svg
        viewBox="-0.75 -0.75 24 24"
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
    >
        <path
            d="M9.375 20.390625a1.9565625000000002 1.9565625000000002 0 0 0 3.7546874999999997 0"
            class="stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m11.25 2.8125 0 -2.109375"
            class="stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M11.25 2.8125a7.03125 7.03125 0 0 1 7.03125 7.03125c0 6.605625 1.40625 7.734375 1.40625 7.734375H2.8125s1.40625 -1.79625 1.40625 -7.734375A7.03125 7.03125 0 0 1 11.25 2.8125Z"
            class="stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
