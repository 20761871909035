<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <line
            x2="16"
            transform="translate(0.5 4.5)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M3.25,2.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25h0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M5.75,2.25A.25.25,0,1,0,6,2.5a.25.25,0,0,0-.25-.25h0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M8.25,2.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25h0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <rect
            width="16"
            height="13"
            rx="2"
            transform="translate(0.5 0.5)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <line
            x2="4.5"
            transform="translate(19 13.5)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M19,9.5h2.5a2,2,0,0,1,2,2v10a2,2,0,0,1-2,2H9.5a2,2,0,0,1-2-2V16"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M7.5,11.5V8a1.5,1.5,0,0,1,3,0v3.5"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <line
            x2="3"
            transform="translate(7.5 9.5)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M17.5,17A1.5,1.5,0,0,1,16,18.5H14.5v-3H16A1.5,1.5,0,0,1,17.5,17Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M17.5,20A1.5,1.5,0,0,1,16,21.5H14.5v-3H16A1.5,1.5,0,0,1,17.5,20Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
    </svg>
</template>
