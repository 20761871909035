import { defineStore } from 'pinia';
import { nextTick, ref, shallowRef } from 'vue';
import api from '@/plugins/axios.js';
import { useValidationErrors } from '@/composables/validation-errors.js';
import { useToast } from 'vue-toastification';
import { useSupportStore } from '@/stores/support.js';
import SupportOptionsStep from '@/components/Ticket/Steps/SupportOptionsStep.vue';
import i18n from '@/utils/i18n.js';

const toast = useToast();

export const useTicketStore = defineStore('ticket', () => {
    const isLoading = ref(false);
    const active = ref(false);
    const component = shallowRef();
    const previousComponent = shallowRef();
    const state = ref({});
    const errors = useValidationErrors();

    const open = (_component = undefined) => {
        if (_component) {
            component.value = _component;
        } else if (!component.value) {
            component.value = SupportOptionsStep;
        }

        active.value = true;
    };

    const close = async () => {
        active.value = false;

        await nextTick(() => {
            // Needed for the closing animation to work
            setTimeout(() => {
                component.value = null;
                previousComponent.value = null;
                state.value = {};
                errors.reset();
            }, 500);
        });
    };

    const setComponent = (newComponent, previous = null, _state = undefined) => {
        previousComponent.value = previous;
        component.value = newComponent;

        if (_state) {
            state.value = _state;
        }
    };

    const goToPrevious = async (_previous = null) => {
        if (_previous) {
            previousComponent.value = _previous;
        }

        if (!previousComponent.value) {
            await close();
        }

        component.value = previousComponent.value;
        previousComponent.value = null;
    };

    const create = async (data) => {
        isLoading.value = true;
        const { addTicket, setActiveTicket } = useSupportStore();

        try {
            const response = await api.post('/general/support-messages', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            addTicket(response.data.data);

            await setActiveTicket(response.data.data);

            await close();

            toast.success(i18n.global.t('support.ticket_created_successfully'));
        } catch (error) {
            if (error.response?.status === 422) {
                errors.setErrors(error.response.data.errors);
                errors.triggerToast();
                return;
            }

            console.error(error);
            toast.error(i18n.global.t('error.could_not_create_ticket'));
        } finally {
            isLoading.value = false;
        }
    };

    const dehydrate = () => {
        state.value = {};
        errors.reset();
    };

    return {
        isLoading,
        active,
        component,
        previousComponent,
        state,
        errors,
        open,
        close,
        setComponent,
        goToPrevious,
        create,
        dehydrate,
    };
});
