<script setup>
import { computed, onMounted, ref, toRefs } from 'vue';
import { useLoader } from '@/stores/loader.js';
import { useAppStore } from '@/stores/app.js';
import CFErrorInfo from '@/components/CFErrorInfo.vue';
import CFGlobalLoader from '@/components/CFGlobalLoader.vue';

const loader = useLoader();
const appStore = useAppStore();

const { hydrating } = toRefs(appStore);
const isMobile = ref(false);

const error = computed(() => appStore.error);

function mobileCheck() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}

onMounted(() => (isMobile.value = mobileCheck()));
</script>

<template>
    <div id="cyberfusion">
        <div
            v-if="isMobile"
            class="flex h-screen w-screen flex-col items-center justify-center bg-gradient-to-b from-[#c8cecc] to-[#bdcbf5] px-[50px] text-center"
        >
            <svg
                class="mb-7.5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-0.5 -0.5 30 30"
                height="50"
                width="50"
            >
                <g>
                    <path
                        d="m11.78125 22.65625 9.96875 0"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                    <path
                        d="M21.75 12.6875v11.78125a3.625 3.625 0 0 1 -3.625 3.625H10.875a3.625 3.625 0 0 1 -2.730833333333333 -1.2083333333333333"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                    <path
                        d="M28.09375 0.90625 0.90625 28.09375"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                    <path
                        d="M21.75 7.25v-0.90625a3.625 3.625 0 0 0 -3.625 -3.625H10.875a3.625 3.625 0 0 0 -3.625 3.625V21.75"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                </g>
            </svg>
            <h1 class="leading-7.5 w-[280px] text-3xl">
                {{ $t('general.desktop_only.title') }}
            </h1>
            <p class="mt-2.5 w-[280px] leading-4">
                {{ $t('general.desktop_only.description') }}
            </p>
        </div>

        <CFErrorInfo v-else-if="error" />

        <RouterView
            v-else-if="!hydrating"
            v-slot="{ Component, route }"
        >
            <component
                :is="Component"
                :key="route.path"
            />
        </RouterView>

        <CFGlobalLoader :loading="loader.loading || hydrating" />
    </div>
</template>

<style scoped>
:global(#app) {
    height: 100%;
}

#cyberfusion {
    height: 100%;
}
</style>
