import dayjs from 'dayjs';
import 'dayjs/locale/nl.js';
import 'dayjs/locale/en.js';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);
dayjs.locale('nl');
