<script setup>
import CFLayoutAuth from '@/views/layouts/CFLayoutAuth.vue';
import CFButton from '@/components/CFButton.vue';
import CFAuthInput from '@/components/Auth/CFAuthInput.vue';
import CFAuthPassword from '@/components/Auth/CFAuthPassword.vue';
import CFAlert from '@/components/CFAlert.vue';
import { login as loginService } from '@/services/auth';
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import api, { resumeQueue } from '@/plugins/axios';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

const $router = useRouter();
const toast = useToast();
const { t } = useI18n();

const twoFactor = ref(false);

const twoFactorInput = ref(null);

const loginForm = ref({
    grant_type: 'password',
    username: '',
    password: '',
});

const twoFactorForm = ref({
    session_token: '',
    secret: '',
});

const isLoading = ref(false);
const error = ref('');

const v$ = useVuelidate(
    {
        loginForm: {
            username: { email, required },
            password: { required },
        },
        twoFactorForm: {
            secret: { required },
        },
    },
    {
        loginForm,
        twoFactorForm,
    }
);

async function onLogin() {
    isLoading.value = true;
    error.value = '';

    const validated = await v$.value.loginForm.$validate();

    if (!validated) {
        isLoading.value = false;
        return;
    }

    try {
        const response = await api.post('/general/oauth/token', loginForm.value, {
            login: true,
        });

        if (response?.data?.data?.type === 'loginSession') {
            twoFactor.value = true;
            twoFactorForm.value.session_token = response.data.data.attributes.token;

            await nextTick(() => {
                twoFactorInput.value.focus();
            });

            return;
        }

        await login(response);
    } catch (e) {
        if (e.response) {
            error.value = e.response.data?.errors?.[0].detail;
        }
    } finally {
        isLoading.value = false;
    }
}

async function twoFactorSubmit() {
    isLoading.value = true;
    error.value = '';

    const validated = await v$.value.twoFactorForm.$validate();

    if (!validated) {
        toast.error(t('validations.required', { property: t('auth.authenticator_code') }));
        isLoading.value = false;
        return;
    }

    resumeQueue();

    try {
        const response = await api.post('/general/oauth/confirm', twoFactorForm.value);

        await login(response);
    } catch (e) {
        error.value = e.response?.data?.errors?.[0].detail;
    } finally {
        isLoading.value = false;
    }
}

async function login(response) {
    try {
        await loginService(response);

        await $router.push({ name: 'dashboard' });
    } catch (e) {
        error.value = e.response?.data?.errors?.[0].detail;
    }
}
</script>

<template>
    <CFLayoutAuth>
        <RouterLink
            class="flex justify-center"
            :to="{ name: 'login' }"
        >
            <img
                class="mb-10 max-w-[80%] lg:max-w-full"
                src="/Cyberfusion_Logo.svg"
                alt="Cyberfusion logo"
            />
        </RouterLink>

        <form
            v-if="!twoFactor"
            class="flex h-80 flex-col px-6 lg:px-0"
            @submit.prevent="onLogin"
        >
            <CFAuthInput
                v-model="loginForm.username"
                type="email"
                :placeholder="$t('form.email')"
                autocomplete="email"
                :errors="v$.loginForm.username.$errors.length"
            />
            <CFAuthPassword
                v-model="loginForm.password"
                :placeholder="$t('form.password')"
                autocomplete="password"
                :errors="v$.loginForm.password.$errors.length"
            />

            <div class="flex justify-between">
                <RouterLink
                    :to="{ name: 'password forgot' }"
                    class="text-base leading-4 underline underline-offset-3"
                >
                    {{ $t('auth.forgot_password') }}?
                </RouterLink>
                <RouterLink
                    :to="{ name: 'sign up' }"
                    class="text-base leading-4 underline underline-offset-3"
                >
                    {{ $t('auth.create_team') }}
                </RouterLink>
            </div>

            <CFButton
                class="mt-auto"
                type="submit"
                color="primary"
                size="auth"
                icon="next-arrow"
                :loading="isLoading"
                outline
            >
                {{ $t('button.login') }}
            </CFButton>
        </form>

        <form
            v-else
            class="flex flex-col space-y-5"
            @submit.prevent="twoFactorSubmit"
        >
            <p class="text-sm">{{ $t('auth.2fa_description') }}</p>
            <CFAuthInput
                ref="twoFactorInput"
                v-model="twoFactorForm.secret"
                name="2fa"
                type="text"
                input-mode="numeric"
                pattern="[0-9]*"
                autocomplete="one-time-code"
                :placeholder="$t('auth.authenticator_code')"
                :errors="v$.twoFactorForm.secret.$errors.length"
            />

            <CFButton
                type="submit"
                color="primary"
                size="auth"
                icon="next-arrow"
                :loading="isLoading"
                outline
            >
                {{ $t('button.login') }}
            </CFButton>
        </form>

        <CFAlert
            v-if="error"
            class="mt-5"
            color="warning"
        >
            {{ error }}
        </CFAlert>
    </CFLayoutAuth>
</template>
