<template>
    <svg
        viewBox="-0.5 -0.5 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.145833333333334 11.5a3.354166666666667 3.354166666666667 0 1 0 6.708333333333334 0 3.354166666666667 3.354166666666667 0 1 0 -6.708333333333334 0"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M22.405833333333334 11.18375C21.284583333333334 9.91875 16.828333333333333 5.270833333333334 11.5 5.270833333333334S1.7154166666666668 9.91875 0.5941666666666667 11.18375a0.49833333333333335 0.49833333333333335 0 0 0 0 0.6325000000000001C1.7154166666666668 13.08125 6.171666666666667 17.729166666666668 11.5 17.729166666666668s9.784583333333334 -4.647916666666666 10.905833333333334 -5.912916666666667a0.49833333333333335 0.49833333333333335 0 0 0 0 -0.6325000000000001Z"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
