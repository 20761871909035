<template>
    <svg
        width="24"
        height="24"
        viewBox="-0.25 -0.25 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m1.858458333333333 17.136395833333335 7.444604166666666 0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m2.4596666666666667 5.386395833333333 18.5885 0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m11.750979166666665 11.261395833333331 -11.250625 0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M11.140958333333334 22.995729166666667A11.260416666666666 11.260416666666666 0 1 1 23.010416666666664 11.260416666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M11.034229166666666 0.5160208333333334c-5.875 6.364583333333333 -5.768270833333333 14.646374999999999 0.10672916666666667 22.47970833333333"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M12.464791666666667 0.5160208333333334A15.79102083333333 15.79102083333333 0 0 1 16.645833333333332 8.8125"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m17.828666666666663 11.681458333333333 1.5059583333333333 3.4956249999999995h2.9375a0.7196874999999999 0.7196874999999999 0 0 1 0.5062291666666666 1.2562708333333332l-2.5458333333333334 2.26775 1.41 3.2439791666666666a0.7686458333333334 0.7686458333333334 0 0 1 -1.0937291666666666 0.9615416666666666L17.135416666666664 20.986479166666665l-3.4084791666666665 1.9201458333333334a0.7686458333333334 0.7686458333333334 0 0 1 -1.0937291666666666 -0.9615416666666666l1.41 -3.2439791666666666 -2.5458333333333334 -2.26775a0.7187083333333333 0.7187083333333333 0 0 1 0.5072083333333334 -1.2562708333333332h2.931625l1.5137916666666666 -3.4956249999999995a0.7833333333333333 0.7833333333333333 0 0 1 1.3786666666666665 0Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
