import { defineStore } from 'pinia';
import api from '@/plugins/axios.js';
import { computed, ref } from 'vue';

export const useTeamStore = defineStore('team', () => {
    const team = ref({});
    const members = ref([]);

    const hasRequiredInformation = computed(() => {
        return team.value.attributes?.provided_required_information ?? false;
    });

    const enforces2FA = computed(() => !!team.value.attributes?.enforce_two_factor);

    const isVerified = computed(() => !!team.value.attributes?.is_verified);

    const isUnlocked = computed(() => !!team.value.attributes?.is_unlocked);

    const getTeam = async () => {
        const { data } = await api.get('/general/team');

        team.value = data.data;

        return team.value;
    };

    const setTeam = (data) => {
        team.value = data;
    };

    const updateTeam = (data) => {
        return api.patch(`/general/team`, data);
    };

    const getMembers = async (params = {}) => {
        try {
            const response = await api.get(`/general/users`, { params });
            members.value = response?.data ?? [];
        } catch (error) {
            members.value = [];
        }

        return members.value;
    };

    const createMember = (data) => {
        return api.post(`/general/users`, data);
    };

    const updateMember = (id, data) => {
        return api.post(`/general/users/${id}`, data);
    };

    const deleteMember = (id) => {
        return api.delete(`/general/users/${id}`);
    };

    const hydrate = async () => {
        await getTeam();
    };

    const dehydrate = () => {
        team.value = {};
        members.value = [];
    };

    return {
        team,
        members,
        hasRequiredInformation,
        enforces2FA,
        isVerified,
        isUnlocked,
        getTeam,
        setTeam,
        updateTeam,
        getMembers,
        createMember,
        updateMember,
        deleteMember,
        hydrate,
        dehydrate,
    };
});
