<script setup>
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon.vue';
import CFButton from '@/components/CFButton.vue';

defineProps({
    certificate: {
        type: Object,
        required: true,
    },
    selectedCertificate: {
        type: [Object, null],
        required: true,
    },
});

const emit = defineEmits(['select']);
</script>

<template>
    <div class="flex items-center gap-4 border-b border-black py-4">
        <span
            class="flex h-4 w-4 shrink-0 items-center justify-center rounded-full"
            :class="[selectedCertificate === certificate ? 'bg-accent' : 'bg-neutrals-400']"
        >
            <ArrowRightIcon
                v-show="selectedCertificate === certificate"
                class="h-3 w-3 text-white"
            />
        </span>
        <span class="grow select-none underline underline-offset-3">
            {{ certificate.attributes.subject_name }}
        </span>
        <CFButton
            type="button"
            size="small"
            class="shrink-0"
            :color="selectedCertificate === certificate ? 'accent' : 'primary'"
            :icon="selectedCertificate === certificate ? 'check' : null"
            :outline="selectedCertificate !== certificate"
            @click="emit('select')"
        >
            <span v-if="selectedCertificate !== certificate">{{ $t('button.select') }}</span>
            <span v-else>{{ $t('button.selected') }}</span>
        </CFButton>
    </div>
</template>
