<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="80"
        viewBox="0 0 100 80"
    >
        <g>
            <g>
                <path
                    d="M5.251,31.75l27,27,27-27h-15v-27a3,3,0,0,0-3-3h-18a3,3,0,0,0-3,3v27Z"
                    transform="translate(13.5)"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
                <path
                    d="M90.751,17.5v12a6,6,0,0,1-6,6h-78a6,6,0,0,1-6-6v-12"
                    transform="translate(0 47.25)"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
            </g>
        </g>
    </svg>
</template>
