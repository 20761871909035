import { defineStore } from 'pinia';
import { onMounted, ref } from 'vue';
import { useLocalStorage, useStorage } from '@vueuse/core';
import api from '@/plugins/axios.js';

export const useAppStore = defineStore('app', () => {
    const hydrated = ref(false); // This indicates that the app is ready to be rendered
    const hydrating = ref(false);
    const error = ref(null);

    const authenticated = ref(false);
    const refreshToken = useLocalStorage('refreshToken', null);
    const accessTokenExpiry = ref(0);

    const showMigrationHelp = useLocalStorage('showMigrationHelp', true);

    const showSearchModal = ref(false);
    const searchQuery = ref('');

    const sidebarIsOpen = useStorage('sidebarIsOpen', true);

    const legalForms = ref([]);
    const salutations = ref([]);

    const getLegalForms = async () => {
        try {
            const response = await api.get(`/general/data/legal-forms`);

            if (!response.data?.data) {
                legalForms.value = [];
            }

            legalForms.value = response.data.data.map((l) => ({
                id: l.id,
                value: l.id,
                name: l.attributes.name,
            }));
        } catch (e) {
            legalForms.value = [];
        }
    };

    const getSalutations = async () => {
        try {
            const response = await api.get(`/general/data/salutations`);

            if (!response.data?.data) {
                salutations.value = [];
            }

            salutations.value = response.data.data.map((s) => ({
                id: s.id,
                name: s.attributes.name,
                value: s.attributes.name,
            }));
        } catch (e) {
            salutations.value = [];
        }
    };

    onMounted(() => {
        void getLegalForms();
        void getSalutations();
    });

    return {
        hydrated,
        hydrating,
        error,
        authenticated,
        refreshToken,
        accessTokenExpiry,
        showMigrationHelp,
        showSearchModal,
        searchQuery,
        sidebarIsOpen,
        legalForms,
        salutations,
        getLegalForms,
        getSalutations,
    };
});
