<template>
    <button
        type="button"
        class="btn btn-rounded-full cursor-pointer border-transparent text-primary before:invisible before:bg-primary after:bg-secondary hover:visible hover:border-primary hover:bg-primary hover:text-primary"
    >
        <svg
            height="24"
            viewBox="-0.5 -0.5 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m0.4791666666666667 0.47820833333333335 22.041666666666668 22.041666666666668"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="m22.520833333333336 0.47820833333333335 -22.041666666666668 22.041666666666668"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
        </svg>
    </button>
</template>
