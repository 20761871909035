<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="-0.5 -0.5 24 24"
        height="14"
        width="14"
    >
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.5 22.042625c5.821875 0 10.541666666666668 -4.719791666666667 10.541666666666668 -10.541666666666668s-4.719791666666667 -10.541666666666668 -10.541666666666668 -10.541666666666668 -10.541666666666668 4.719791666666667 -10.541666666666668 10.541666666666668 4.719791666666667 10.541666666666668 10.541666666666668 10.541666666666668Z"
            stroke-width="1.5"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.625 9.588125000000002a2.875 2.875 0 1 1 4.025 2.635416666666667 1.9166666666666667 1.9166666666666667 0 0 0 -1.15 1.756625V14.375"
            stroke-width="1.5"
        ></path>
        <path
            stroke="#000000"
            d="M11.503833333333333 16.770833333333336a0.23958333333333334 0.23958333333333334 0 1 1 0 -0.4791666666666667"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            d="M11.503833333333333 16.770833333333336a0.23958333333333334 0.23958333333333334 0 1 0 0 -0.4791666666666667"
            stroke-width="2"
        ></path>
    </svg>
</template>
