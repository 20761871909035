<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="-0.5 -0.5 30 30"
        height="30"
        width="30"
    >
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.782458333333333 4.53125h1.8125"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.21995833333333 4.53125h1.8125"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.21995833333333 11.78125h1.8125"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.782458333333333 11.78125h1.8125"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M5.4387083333333335 22.65625h4.53125v-7.25"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M22.657458333333334 4.53125a3.625 3.625 0 0 1 -3.625 3.625h-14.5a3.625 3.625 0 1 1 0 -7.25h14.5a3.625 3.625 0 0 1 3.625 3.625v0Z"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.407458333333333 15.406249999999998h-10.875a3.625 3.625 0 1 1 0 -7.25h14.5a3.625 3.625 0 0 1 3.134416666666666 1.8028333333333333"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13.594958333333333 22.65625h-3.625"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M26.282458333333334 19.03125h-7.25a1.8125 1.8125 0 0 0 -1.8125 1.8125v5.4375a1.8125 1.8125 0 0 0 1.8125 1.8125h7.25a1.8125 1.8125 0 0 0 1.8125 -1.8125v-5.4375a1.8125 1.8125 0 0 0 -1.8125 -1.8125Z"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M22.657458333333334 13.59375a3.625 3.625 0 0 0 -3.625 3.625v1.8125h7.25v-1.8125a3.625 3.625 0 0 0 -3.625 -3.625Z"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            d="M22.657458333333334 24.050666666666665a0.453125 0.453125 0 0 1 0 -0.90625"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            d="M22.657458333333334 24.050666666666665a0.453125 0.453125 0 0 0 0 -0.90625"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            d="M1.360583333333333 22.65625a0.453125 0.453125 0 1 1 0 -0.90625"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            d="M1.360583333333333 22.65625a0.453125 0.453125 0 1 0 0 -0.90625"
            stroke-width="1"
        ></path>
        <g>
            <path
                stroke="currentColor"
                d="M5.4375 12.234375a0.453125 0.453125 0 1 1 0 -0.90625"
                stroke-width="1"
            ></path>
            <path
                stroke="currentColor"
                d="M5.4375 12.234375a0.453125 0.453125 0 1 0 0 -0.90625"
                stroke-width="1"
            ></path>
        </g>
        <g>
            <path
                stroke="currentColor"
                d="M5.4375 4.984375a0.453125 0.453125 0 1 1 0 -0.90625"
                stroke-width="1"
            ></path>
            <path
                stroke="currentColor"
                d="M5.4375 4.984375a0.453125 0.453125 0 1 0 0 -0.90625"
                stroke-width="1"
            ></path>
        </g>
    </svg>
</template>
