<script setup>
import { computed } from 'vue';

const props = defineProps({
    value: {
        type: String,
        required: true,
    },
    query: {
        type: String,
        default: '',
    },
});

const markedString = computed(() => {
    let queries = props.query
        .replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
        .split(' ')
        .filter((val) => val !== '')
        .join('|');

    let value = props.value;
    value = value.replaceAll(new RegExp(`(${queries})`, 'gi'), '<mark>$&</mark>');

    return value;
});
</script>

<template>
    <span
        class="grow text-base font-bold"
        v-html="markedString"
    />
</template>
