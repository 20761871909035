<template>
    <svg
        viewBox="-0.5 -0.5 30 30"
        xmlns="http://www.w3.org/2000/svg"
        height="12"
        width="12"
    >
        <path
            d="M1.7786666666666666 16.13970833333333a10.951125 10.951125 0 1 0 20.157416666666663 -8.567083333333333 10.951125 10.951125 0 1 0 -20.157416666666663 8.567083333333333Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m19.600375 19.599166666666665 8.493374999999999 8.494583333333333"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
