import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';
import { useUserStore } from '@/stores/user.js';
import dayjs from 'dayjs';

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
    nl: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
};

export const availableLanguages = [
    {
        name: 'English',
        locale: 'en',
    },
    {
        name: 'Nederlands',
        locale: 'nl',
    },
];

export const i18n = createI18n({
    messages,
    numberFormats,
    legacy: false,
    missingWarn: true,
    fallbackWarn: true,
    globalInjection: true,
    locale: navigator.language.split('-')[0],
    fallbackLocale: 'nl',
});

export function setLanguage(lang = null) {
    const usersStore = useUserStore();

    if (!lang) {
        lang = navigator.language.split('-')[0]; // fallback
    }

    if (usersStore.user && usersStore.language) {
        lang = usersStore.language;
    }

    i18n.global.locale.value = lang;
    dayjs.locale(lang);

    document.querySelector('html').setAttribute('lang', lang);
}

export default i18n;
