import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { nanoid } from 'nanoid';

export const useRequestsStore = defineStore('requests', () => {
    const queue = ref([]);

    const queueHasItems = computed(() => queue.value.length > 0);

    const startRequest = () => {
        const id = nanoid();
        queue.value = [...queue.value, id];

        // If requests take more than 3.5 seconds, we can assume they'll either never
        // happen, or already crashed
        setTimeout(() => endRequest(id), 3500);

        return id;
    };

    const endRequest = (id) => {
        queue.value = queue.value.filter((queueID) => queueID !== id);
    };

    return {
        queue,
        queueHasItems,
        startRequest,
        endRequest,
    };
});
