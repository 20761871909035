<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31.308"
        height="31.197"
        viewBox="0 0 31.308 31.197"
    >
        <g transform="translate(-0.057 -0.25)">
            <path
                d="M9.713,14.732a2,2,0,0,1,0-2.828h0a2,2,0,0,0,0-2.83L6.176,5.539a2,2,0,0,0-2.828,0l-.84.839a6,6,0,0,0-.819,7.467l.008.013A54.147,54.147,0,0,0,16.245,28.4l.013.009a6,6,0,0,0,7.467-.82l.839-.839a2,2,0,0,0,0-2.83L21.027,20.39a2,2,0,0,0-2.83,0h0a2,2,0,0,1-2.828,0"
                transform="translate(0 1.402)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M19.5.75a8,8,0,0,0-6.974,11.921L10.5,18.752l5.9-2.623A8,8,0,1,0,19.5.75Z"
                transform="translate(3.252)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                y1="4.834"
                transform="translate(22.752 4.25)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M17.625,9a.75.75,0,1,0,.75.75.75.75,0,0,0-.75-.75h0"
                transform="translate(5.127 2.085)"
            />
        </g>
    </svg>
</template>
