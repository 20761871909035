<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
    >
        <g transform="translate(-0.25 -0.25)">
            <path
                d="M4.917,19H4.083A3.333,3.333,0,0,1,.75,15.667V12.333A3.333,3.333,0,0,1,4.083,9h.833a.833.833,0,0,1,.833.833v8.333A.833.833,0,0,1,4.917,19Z"
                transform="translate(0 0.917)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M20.417,19h-.833a.833.833,0,0,1-.833-.833V9.833A.833.833,0,0,1,19.583,9h.833a3.333,3.333,0,0,1,3.333,3.333v3.333A3.333,3.333,0,0,1,20.417,19Z"
                transform="translate(2 0.917)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M3.75,9.917A9.167,9.167,0,0,1,12.917.75h0a9.167,9.167,0,0,1,9.167,9.167"
                transform="translate(0.333)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M15,22.167h2.5a3.333,3.333,0,0,0,3.333-3.333h0V18"
                transform="translate(1.583 1.917)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M13.833,23.583H12.167A1.667,1.667,0,0,1,10.5,21.917h0a1.667,1.667,0,0,1,1.667-1.667h1.667A1.667,1.667,0,0,1,15.5,21.917h0A1.667,1.667,0,0,1,13.833,23.583Z"
                transform="translate(1.083 2.167)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
        </g>
    </svg>
</template>
