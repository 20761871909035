import api from '@/plugins/axios.js';
import { nextTick, ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import commands from '@/utils/commands.js';
import Fuse from 'fuse.js';
import { useAppStore } from '@/stores/app.js';

export const useSearch = () => {
    const appStore = useAppStore();
    const isLoading = ref(false);
    const searchQuery = ref('');
    const searchables = ref([]);
    const searchResults = ref([]);

    const search = useDebounceFn(async (type = null) => {
        isLoading.value = true;

        appStore.searchQuery = searchQuery.value;

        if (!searchQuery.value) {
            searchResults.value = [];
            isLoading.value = false;
            return;
        }

        searchables.value = commands;

        switch (type) {
            case 'page':
                break;
            case 'domain':
                await fetchDomains(searchQuery.value);
                break;
            case 'certificate':
                await fetchCertificates(searchQuery.value);
                break;
            case 'contact':
                await fetchCompanies(searchQuery.value);
                await fetchContacts(searchQuery.value);
                break;
            default:
                await fetchDomains(searchQuery.value);
                await fetchCertificates(searchQuery.value);
                await fetchCompanies(searchQuery.value);
                await fetchContacts(searchQuery.value);
                break;
        }

        const fuse = new Fuse(searchables.value, {
            keys: ['label', 'keywords'],
            threshold: 0.3,
            includeMatches: false,
            includeScore: false,
            ignoreFieldNorm: true,
        });

        searchResults.value = fuse.search(searchQuery.value, { limit: 50 });

        isLoading.value = false;
    }, 500);

    const fetchDomains = async (query) => {
        try {
            const response = await api.get(`/general/domains`, { params: { search: query } });

            if (response.data?.data) {
                let domains = response.data.data.map((domain) => {
                    return {
                        name: 'domains/show-' + domain.id,
                        label: domain.attributes.name + '.' + domain.attributes.tld,
                        keywords: [],
                        type: 'domain',
                        to: { name: 'single domain', params: { domain: domain.id } },
                    };
                });

                searchables.value = searchables.value.concat(domains);
            }
        } catch (e) {
            /* empty */
        }
    };

    const fetchCertificates = async (query) => {
        try {
            const response = await api.get(`/general/ssl-certificates`, {
                params: { search: query },
            });

            if (response.data?.data) {
                let certificates = response.data.data.map((certificate) => {
                    return {
                        name: 'certificates/show-' + certificate.id,
                        label: certificate.attributes.common_name,
                        keywords: [],
                        type: 'certificate',
                        to: { name: 'single ssl', params: { ssl: certificate.id } },
                    };
                });

                searchables.value = searchables.value.concat(certificates);
            }
        } catch (e) {
            /* empty */
        }
    };

    const fetchCompanies = async (query) => {
        try {
            const response = await api.get(`/general/companies`, {
                params: { search: query },
            });

            if (response.data?.data) {
                let companies = response.data.data.map((company) => {
                    return {
                        name: 'companies/show-' + company.id,
                        label: company.attributes.name,
                        keywords: [],
                        type: 'contact',
                        to: { name: 'single company', params: { company: company.id } },
                    };
                });

                searchables.value = searchables.value.concat(companies);
            }
        } catch (e) {
            /* empty */
        }
    };

    const fetchContacts = async (query) => {
        try {
            const response = await api.get(`/general/contacts`, {
                params: { search: query },
            });

            if (response.data?.data) {
                let contacts = response.data.data.map((contact) => {
                    return {
                        name: 'contacts/show-' + contact.id,
                        label: contact.attributes.first_name + ' ' + contact.attributes.last_name,
                        keywords: [],
                        type: 'contact',
                        to: { name: 'single contact', params: { contact: contact.id } },
                    };
                });

                searchables.value = searchables.value.concat(contacts);
            }
        } catch (e) {
            /* empty */
        }
    };

    watch(searchQuery, () => {
        isLoading.value = true;

        void nextTick(() => {
            void search();
        });
    });

    return {
        isLoading,
        searchQuery,
        searchResults,
        search,
    };
};
