<script setup>
import { computed } from 'vue';

const props = defineProps({
    rounded: {
        type: Boolean,
        default: false,
    },
    centered: {
        type: Boolean,
        default: false,
    },
    animated: {
        type: Boolean,
        default: true,
    },
});

const classObject = computed(() => {
    return {
        'content-placeholders-is-rounded': props.rounded,
        'content-placeholders-is-centered': props.centered,
        'content-placeholders-is-animated': props.animated,
    };
});
</script>

<template>
    <div :class="classObject">
        <slot />
    </div>
</template>
