<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 20.47 17.64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            class="fill-none stroke-current"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M.5,8.64h16.64"
        />
        <path
            class="fill-none stroke-current"
            d="M9.37.5L.75,8.08c-.31.27-.34.74-.07,1.04.02.02.04.05.07.07l8.61,7.58"
        />
        <path
            class="fill-none stroke-current"
            d="M19.97.5v16.64"
        />
    </svg>
</template>
