<script setup>
import SearchIcon from '@/components/Icons/SearchIcon.vue';
import { useAppStore } from '@/stores/app.js';
import { Tooltip as VueTooltip } from 'floating-vue';
import { useShortcuts } from '@/composables/useShortcuts.js';
import CFKbd from '@/components/CFKbd.vue';
import { defineShortcuts } from '@/composables/defineShortcuts.js';

const appStore = useAppStore();

const { metaSymbol } = useShortcuts();

defineShortcuts({
    '?': {
        handler: () => {
            appStore.showSearchModal = !appStore.showSearchModal;
        },
    },
    'meta_/': {
        handler: () => {
            appStore.showSearchModal = !appStore.showSearchModal;
        },
    },
    'escape': {
        whenever: [appStore.showSearchModal],
        handler: () => {
            appStore.showSearchModal = false;
        },
    },
});
</script>

<template>
    <VueTooltip theme="dark-tooltip">
        <button
            type="button"
            class="btn btn-rounded-full border-primary text-secondary before:bg-primary after:bg-secondary hover:text-primary"
            @click="appStore.showSearchModal = true"
        >
            <SearchIcon class="h-6 w-6" />
        </button>

        <template #popper>
            <div class="flex flex-col items-center">
                <strong>{{ $t('shortcuts.search') }}</strong>
                <CFKbd
                    :value="metaSymbol + ' + /'"
                    class="bg-primary text-neutrals-600 ring-primary"
                />
            </div>
        </template>
    </VueTooltip>
</template>
