import axios from 'axios';
import PQueue from 'p-queue';
import { useToast } from 'vue-toastification';
import { useLoader } from '@/stores/loader.js';
import Bugsnag from '@bugsnag/js';
import { useRequestsStore } from '@/stores/requests.js';
import { logout, LogoutReason, refresh } from '@/services/auth.js';
import i18n from '@/utils/i18n.js';

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL.replace(/\/$/, ''),
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

let queue = new PQueue({
    concurrency: 5,
    intervalCap: 5,
    interval: 500,
    carryoverConcurrencyCount: true,
});

/**
 * Auth request interceptor.
 *
 * @param config
 */
function onRequest(config) {
    const loader = useLoader();
    const requestsStore = useRequestsStore();
    const id = requestsStore.startRequest();

    const requestConfig = {
        id: id,
        ...config,
    };

    const loaderRequestUrls = [
        /\/general\/domains\/register/,
        /\/general\/domains\/transfer/,
        /\/general\/domains\/.*?\/nameservers/,
        /\/general\/ssl-certificates\/request-certificate/,
    ];

    if (config?.showLoader && loaderRequestUrls.some((regex) => regex.test(config?.url))) {
        loader.pending();
    }

    return new Promise((resolve) => {
        if (config.url && config.url === '/general/oauth/token') {
            queue.pause();
            return resolve(requestConfig);
        }

        queue.add(() => {
            return resolve(requestConfig);
        });
    });
}

/**
 * Handle request error.
 *
 * @param error
 */
function handleRequestError(error) {
    const loader = useLoader();
    const requestsStore = useRequestsStore();

    if (error.config?.showLoader) {
        loader.done();
    }

    const id = error.config?.id;

    if (id) {
        requestsStore.endRequest(id);
    }

    return Promise.reject(error);
}

/**
 * Any status codes that lie within the range of 2xx cause this function to trigger.
 *
 * @param response
 */
const onResponse = (response) => {
    const loader = useLoader();
    const requestsStore = useRequestsStore();

    const id = response.config?.id;

    if (id) {
        requestsStore.endRequest(id);
    }

    if (response.config?.showLoader) {
        loader.done();
    }

    return response;
};

const refreshExpiredTokenClosure = () => {
    let isCalled = false;
    let runningPromise = undefined;

    return () => {
        if (isCalled) {
            return runningPromise;
        }

        isCalled = true;
        runningPromise = refresh({ navigate: true });

        return runningPromise;
    };
};

const refreshExpiredToken = refreshExpiredTokenClosure();

/**
 * Session expired response interceptor.
 *
 * @param error
 */
async function handleResponseError(error) {
    const requestsStore = useRequestsStore();
    const loader = useLoader();

    // Cancelled requests don't respond with the config
    const id = error.response?.config?.id;

    if (id) {
        requestsStore.endRequest(id);
    }

    if (error.config?.showLoader) {
        loader.done();
    }

    const status = error.response?.status;

    if (!status) {
        return Promise.reject(error);
    }

    const originalRequest = error.config;

    // Check if we are dealing with a 403 error = Forbidden when trying to refresh the token
    if (status === 403 && originalRequest.url === '/general/oauth/token') {
        console.log('403 error when trying to refresh token');

        await logout({ navigate: false, reason: LogoutReason.SESSION_EXPIRED });

        return Promise.reject(error);
    }

    // Check if we are dealing with a 401 error = Unauthorized
    if (status === 401) {
        if (!originalRequest._retry && originalRequest.url !== '/general/oauth/token') {
            console.log('Trying to refresh token due to 401 error');

            originalRequest._retry = true;

            // Try to refresh the token
            originalRequest.headers['Authorization'] = await refreshExpiredToken();

            // Retry the original request after refresh
            return api.request(originalRequest);
        }

        if (!originalRequest.login) {
            useToast().error(i18n.global.t('error.expired_session'));

            console.log('Logging out due to 401 error');

            // Logout if we are still unauthorized and redirect to login
            await logout({
                navigate: originalRequest.url !== '/general/oauth/token',
                reason: LogoutReason.SESSION_EXPIRED,
            });
        }

        return Promise.reject(error);
    }

    // Catch blob responses and parse them as JSON
    if (
        error.request?.responseType === 'blob' &&
        error.response?.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                error.response.data = JSON.parse(reader.result);
                resolve(Promise.reject(error));
            };

            reader.onerror = () => {
                reject(error);
            };

            reader.readAsText(error.response.data);
        });
    }

    const excludeToastStatusCodes = [400, 401, 404, 409, 422];
    const excludeBugsnagStatusCodes = [400, 401, 403, 404, 409, 422];
    const customToastExceptionMessages = ['NotFoundHttpException'];

    const err = error.response?.data?.errors?.[0] ?? {};

    if (!originalRequest.silenceErrors && !excludeToastStatusCodes.includes(status)) {
        const toast = useToast();

        if (customToastExceptionMessages.includes(err.title)) {
            // TODO: Add custom toast messages for specific exceptions
            toast.error('Something went wrong!');
        } else {
            toast.error(err.detail ?? err.title ?? 'Something went wrong!');
        }
    }

    if (!excludeBugsnagStatusCodes.includes(status)) {
        Bugsnag.notify(error);
    }

    return Promise.reject(error);
}

api.defaults.showLoader = true;

api.interceptors.request.use(onRequest, handleRequestError);
api.interceptors.response.use(onResponse, handleResponseError);

export default api;

export function resumeQueue() {
    if (!queue.isPaused) {
        return;
    }

    queue.start();
}
