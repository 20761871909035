<template>
    <svg
        width="24"
        height="24"
        fill="none"
        viewBox="-0.25 -0.25 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.9958124999999999 4.410078541666667h19.583333333333332"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M9.8083125 16.15625h-6.854166666666666c-0.519379375 0 -1.01749125 -0.20631041666666666 -1.3847570833333334 -0.5735958333333333 -0.36725604166666664 -0.36728541666666664 -0.5735762499999999 -0.8653875 -0.5735762499999999 -1.3847375v-11.75c0 -0.519379375 0.20632020833333334 -1.01749125 0.5735762499999999 -1.3847472916666665C1.9366545833333333 0.6959074583333332 2.4347664583333333 0.4895833333333333 2.954145833333333 0.4895833333333333h15.666666666666666c0.51935 0 1.0174520833333331 0.206324125 1.3847375 0.5735860416666667 0.36728541666666664 0.36725604166666664 0.5735958333333333 0.8653679166666667 0.5735958333333333 1.3847472916666665v5.875"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M22.03125 15.177083333333332h-6.854166666666666c-0.54079375 0 -0.9791666666666666 0.4383729166666666 -0.9791666666666666 0.9791666666666666v5.874020833333333c0 0.54079375 0.4383729166666666 0.9791666666666666 0.9791666666666666 0.9791666666666666h6.854166666666666c0.54079375 0 0.9791666666666666 -0.4383729166666666 0.9791666666666666 -0.9791666666666666V16.15625c0 -0.54079375 -0.4383729166666666 -0.9791666666666666 -0.9791666666666666 -0.9791666666666666Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M18.604166666666664 20.379591666666663c0.6760166666666666 0 1.2239583333333333 -0.5479416666666667 1.2239583333333333 -1.2239583333333333 0 -0.67591875 -0.5479416666666667 -1.2239583333333333 -1.2239583333333333 -1.2239583333333333s-1.2239583333333333 0.5480395833333332 -1.2239583333333333 1.2239583333333333c0 0.6760166666666666 0.5479416666666667 1.2239583333333333 1.2239583333333333 1.2239583333333333Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M21.052083333333332 13.708333333333332c0 -0.6491875 -0.2579125 -1.2718395833333331 -0.7169458333333333 -1.7309708333333333C19.87600625 11.518329166666668 19.253354166666668 11.260416666666666 18.604166666666664 11.260416666666666s-1.2718395833333331 0.2579125 -1.7309708333333333 0.7169458333333333C16.4141625 12.43649375 16.15625 13.059145833333332 16.15625 13.708333333333332v1.46875h4.895833333333333V13.708333333333332Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M3.9333125 2.6965368749999996c-0.13519354166666667 0 -0.24479166666666666 -0.10959812499999999 -0.24479166666666666 -0.24479166666666666s0.10959812499999999 -0.24479166666666666 0.24479166666666666 -0.24479166666666666"
            stroke="currentColor"
            stroke-width="1"
        ></path>
        <path
            d="M3.9333125 2.6965368749999996c0.13519354166666667 0 0.24479166666666666 -0.10959812499999999 0.24479166666666666 -0.24479166666666666s-0.10959812499999999 -0.24479166666666666 -0.24479166666666666 -0.24479166666666666"
            stroke="currentColor"
            stroke-width="1"
        ></path>
        <g>
            <path
                d="M5.8916458333333335 2.6965368749999996c-0.13519354166666667 0 -0.24479166666666666 -0.10959812499999999 -0.24479166666666666 -0.24479166666666666s0.10959812499999999 -0.24479166666666666 0.24479166666666666 -0.24479166666666666"
                stroke="currentColor"
                stroke-width="1"
            ></path>
            <path
                d="M5.8916458333333335 2.6965368749999996c0.13519354166666667 0 0.24479166666666666 -0.10959812499999999 0.24479166666666666 -0.24479166666666666s-0.10959812499999999 -0.24479166666666666 -0.24479166666666666 -0.24479166666666666"
                stroke="currentColor"
                stroke-width="1"
            ></path>
        </g>
        <g>
            <path
                d="M7.849979166666666 2.6965368749999996c-0.13519354166666667 0 -0.24479166666666666 -0.10959812499999999 -0.24479166666666666 -0.24479166666666666s0.10959812499999999 -0.24479166666666666 0.24479166666666666 -0.24479166666666666"
                stroke="currentColor"
                stroke-width="1"
            ></path>
            <path
                d="M7.849979166666666 2.6965368749999996c0.13519354166666667 0 0.24479166666666666 -0.10959812499999999 0.24479166666666666 -0.24479166666666666s-0.10959812499999999 -0.24479166666666666 -0.24479166666666666 -0.24479166666666666"
                stroke="currentColor"
                stroke-width="1"
            ></path>
        </g>
    </svg>
</template>
