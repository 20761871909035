<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="currentColor"
    >
        <path
            d="M15.522,4.5,14.261,16.471a1.391,1.391,0,0,1-1.383,1.246H5.643A1.391,1.391,0,0,1,4.26,16.472L3,4.5"
            transform="translate(-0.761 -1.217)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <line
            x2="16"
            transform="translate(0.5 3.283)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <path
            d="M7.5,3.283V1.2A.7.7,0,0,1,8.2.5h4.87a.7.7,0,0,1,.7.7V3.283"
            transform="translate(-2.13 0)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <line
            y2="7.304"
            transform="translate(8.5 6.413)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <line
            x1="0.348"
            y2="7.304"
            transform="translate(11.283 6.413)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
        <line
            x2="0.348"
            y2="7.304"
            transform="translate(5.37 6.413)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        />
    </svg>
</template>
