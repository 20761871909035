<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
    >
        <g transform="translate(-0.25 -0.25)">
            <path
                d="M6.75,6.25a2.5,2.5,0,1,1,2.5,2.5"
                transform="translate(2 1)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                transform="translate(11.25 12.75)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M8.75,9.75a.5.5,0,1,0,.5.5.5.5,0,0,0-.5-.5"
                transform="translate(2.5 3)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                y2="6"
                transform="translate(22.75 2.75)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M15.75.75a2,2,0,0,1,2,2"
                transform="translate(5)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                x2="18"
                transform="translate(2.75 0.75)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M.75,2.75a2,2,0,0,1,2-2"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                y1="14"
                transform="translate(0.75 2.75)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M2.75,14.75a2,2,0,0,1-2-2"
                transform="translate(0 4)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                x1="4"
                transform="translate(2.75 18.75)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                y1="6"
                transform="translate(6.75 18.75)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                x1="4"
                y2="3"
                transform="translate(6.75 21.75)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M27.25,21.75h-4v6l-6-6h-6v-12h16Z"
                transform="translate(3.5 3)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
        </g>
    </svg>
</template>
