<template>
    <svg
        width="24"
        height="24"
        viewBox="-0.25 -0.25 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.666666666666666 21.052083333333332h0.9791666666666666c1.893708333333333 0 3.427083333333333 -1.0447708333333332 3.427083333333333 -2.9375v-2.1247916666666664"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M19.09375 8.322916666666666a3.9166666666666665 3.9166666666666665 0 0 1 1.3062083333333334 7.610083333333333 0.9791666666666666 0.9791666666666666 0 0 1 -1.3062083333333334 -0.922375Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M4.40625 8.322916666666666a3.9166666666666665 3.9166666666666665 0 0 0 -1.3062083333333334 7.610083333333333A0.9791666666666666 0.9791666666666666 0 0 0 4.40625 15.010625Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M19.09375 9.791666666666666V7.833333333333333a7.34375 7.34375 0 0 0 -14.6875 0v1.9583333333333333"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M11.260416666666666 20.072916666666664h3.9166666666666665a0.4895833333333333 0.4895833333333333 0 0 1 0.4895833333333333 0.4895833333333333v0.9791666666666666a0.4895833333333333 0.4895833333333333 0 0 1 -0.4895833333333333 0.4895833333333333h-3.9166666666666665a0.9791666666666666 0.9791666666666666 0 0 1 -0.9791666666666666 -0.9791666666666666v0a0.9791666666666666 0.9791666666666666 0 0 1 0.9791666666666666 -0.9791666666666666Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
