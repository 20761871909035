export default function ensureTwoFactorAuthenticationIsEnabled({
    redirect,
    to,
    next,
    shouldEnforce2FA,
}) {
    if (shouldEnforce2FA && to.name !== 'tfa setup') {
        return redirect({ name: 'tfa setup' });
    }

    return next();
}
