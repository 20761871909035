<script setup>
import { onMounted, onUnmounted, ref } from 'vue';

const props = defineProps({
    options: {
        type: Object,
        default: () => ({
            root: null, // reference object, used to base the visibility of the watched element. It might be any ancestor of the object or the browser viewport if null.
            rootMargin: '0px',
            threshold: 0, // from 0 to 1; at what percent of the target’s visibility the observer callback should be executed, with 0 meaning as soon as even one pixel is visible
        }),
    },
});

const emit = defineEmits(['intersected']);

const trigger = ref();
const observer = ref();

const handleIntersect = (entry) => {
    if (entry.isIntersecting) {
        emit('intersected');
    }
};

onMounted(() => {
    observer.value = new IntersectionObserver((entries) => {
        handleIntersect(entries[0]);
    }, props.options);

    observer.value.observe(trigger.value);
});

onUnmounted(() => {
    observer.value.disconnect();
});
</script>

<template>
    <span
        ref="trigger"
        class="block w-full"
    />
</template>
