export default function middlewarePipeline(context, middleware, index) {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) {
        return () => true;
    }

    return () => {
        const nextPipeline = middlewarePipeline(context, middleware, index + 1);

        nextMiddleware({ ...context, next: nextPipeline });
    };
}
