<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
    >
        <g transform="translate(-1 -1)">
            <line
                x2="5"
                transform="translate(16.5 36.5)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <path
                d="M26,16.014a10,10,0,1,0-12.5,9.647v2.853h5V25.661A9.977,9.977,0,0,0,26,16.014Z"
                transform="translate(3 2.986)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                y1="2.5"
                transform="translate(19 1.5)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                x2="2.5"
                transform="translate(34 16.5)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                x2="2.5"
                transform="translate(1.5 19)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                x2="1.768"
                y2="1.768"
                transform="translate(29.607 28.357)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                x2="1.768"
                y2="1.768"
                transform="translate(6.625 5.375)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                y1="1.768"
                x2="1.768"
                transform="translate(29.607 5.375)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
            <line
                y1="1.768"
                x2="1.768"
                transform="translate(6.625 28.357)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            />
        </g>
    </svg>
</template>
