import { defineStore } from 'pinia';
import api from '@/plugins/axios.js';
import { Response } from '@/plugins/response.js';

export const useDomainsStore = defineStore({
    id: 'domains',
    state: () => ({
        domainProducts: [],
        isLoading: false,
    }),
    getters: {
        domains: (state) => state.domainProducts?.data ?? [],
        totalDomains: (state) => state.domainProducts?.meta?.total ?? 0,
    },
    actions: {
        async hydrate() {
            await this.getDomains();
        },
        dehydrate() {
            this.clear();
        },
        clear() {
            this.domainProducts = null;
        },
        checkDomainAvailable(domain) {
            return api.get(`/general/domains/check/${domain}`);
        },
        parseDomain(domain) {
            return api.get(`/general/domains/parse/${domain}`);
        },
        getPopularTlds() {
            return api.get('/general/domains/tlds');
        },
        async getDomains(params = {}) {
            try {
                const response = await api.get(`/general/domains`, { params });

                this.domainProducts = response?.data ?? [];
            } catch (error) {
                this.domainProducts = [];
            }
        },
        async getDomain(domain) {
            try {
                return new Response(await api.get(`/general/domains/${domain}`));
            } catch (error) {
                return new Error(error);
            }
        },
        orderDomain(data, action) {
            return api.post(`/general/domains/${action}`, data);
        },
        updateDomain(domain, data) {
            return api.patch(`/general/domains/${domain}`, data);
        },
        cancelDomain(domain) {
            return api.delete(`/general/domains/${domain}`);
        },
        restoreDomain(domain) {
            return api.post(`general/domains/${domain}/recover`);
        },
        retryTransfer(domain, data) {
            return api.post(`general/domains/${domain}/retry-transfer`, data);
        },
        registerDomain(data) {
            return api.post(`/general/domains/register`, data);
        },
        toggleDomainLock(domain, method) {
            return api({
                method,
                url: `/general/domains/${domain}/lock`,
            });
        },
        getDomainEpp(domain) {
            return api.get(`/general/domains/${domain}/epp`);
        },
        setDomainNameservers(domain, data) {
            return api.put(`/general/domains/${domain}/nameservers`, {
                nameservers: data,
            });
        },
    },
});
