<template>
    <svg
        width="24"
        height="24"
        viewBox="-0.25 -0.25 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.46875 16.15625h7.833333333333333s0.9791666666666666 0 0.9791666666666666 0.9791666666666666v4.895833333333333s0 0.9791666666666666 -0.9791666666666666 0.9791666666666666h-7.833333333333333s-0.9791666666666666 0 -0.9791666666666666 -0.9791666666666666v-4.895833333333333s0 -0.9791666666666666 0.9791666666666666 -0.9791666666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m22.03125 23.010416666666664 -7.833333333333333 0s-0.9791666666666666 0 -0.9791666666666666 -0.9791666666666666l0 -10.770833333333332s0 -0.9791666666666666 0.9791666666666666 -0.9791666666666666l7.833333333333333 0s0.9791666666666666 0 0.9791666666666666 0.9791666666666666l0 10.770833333333332s0 0.9791666666666666 -0.9791666666666666 0.9791666666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m22.03125 7.34375 -7.833333333333333 0s-0.9791666666666666 0 -0.9791666666666666 -0.9791666666666666l0 -4.895833333333333s0 -0.9791666666666666 0.9791666666666666 -0.9791666666666666l7.833333333333333 0s0.9791666666666666 0 0.9791666666666666 0.9791666666666666l0 4.895833333333333s0 0.9791666666666666 -0.9791666666666666 0.9791666666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M1.46875 0.4895833333333333h7.833333333333333s0.9791666666666666 0 0.9791666666666666 0.9791666666666666v10.770833333333332s0 0.9791666666666666 -0.9791666666666666 0.9791666666666666h-7.833333333333333s-0.9791666666666666 0 -0.9791666666666666 -0.9791666666666666v-10.770833333333332s0 -0.9791666666666666 0.9791666666666666 -0.9791666666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
