<script setup>
import { computed } from 'vue';
import { twMerge } from 'tailwind-merge';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    value: {
        type: String,
        default: null,
    },
    class: {
        type: [String, Object, Array],
        default: '',
    },
});

const kbdClass = computed(() => {
    return twMerge(
        'inline-flex items-center justify-center text-gray-900 px-1 h-6 min-w-[24px] text-[12px] font-sans rounded bg-gray-100 ring-1 ring-gray-300 ring-inset',
        props.class
    );
});
</script>

<template>
    <kbd
        :class="kbdClass"
        v-bind="$attrs"
    >
        <slot>{{ value }}</slot>
    </kbd>
</template>
