<template>
    <svg
        width="24"
        height="24"
        viewBox="-0.25 -0.25 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="m14.197916666666666 21.140208333333334 0 1.3806249999999998"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M19.09375 23.010416666666664a4.895833333333333 4.895833333333333 0 0 0 -9.791666666666666 0"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M10.770833333333332 13.21875a3.427083333333333 3.427083333333333 0 1 0 6.854166666666666 0 3.427083333333333 3.427083333333333 0 1 0 -6.854166666666666 0"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M7.392708333333333 21.463333333333335a4.17125 4.17125 0 0 0 -5.875 1.5470833333333334"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M2.633958333333333 15.167291666666666A4.895833333333333 4.895833333333333 0 0 0 8.029166666666665 16.254166666666666"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M2.2325 16.645833333333332a2.908125 2.908125 0 1 0 5.81625 0 2.908125 2.908125 0 1 0 -5.81625 0"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="m22.03125 23.010416666666664 0 -22.520833333333332"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M22.03125 0.4895833333333333h-5.875a0.9791666666666666 0.9791666666666666 0 0 0 -0.9791666666666666 0.9791666666666666v2.9375a0.9791666666666666 0.9791666666666666 0 0 0 0.9791666666666666 0.9791666666666666h5.875"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M15.177083333333332 2.4479166666666665H8.8125L11.260416666666666 4.895833333333333 8.8125 7.34375h7.34375a0.9791666666666666 0.9791666666666666 0 0 0 0.9791666666666666 -0.9791666666666666v-0.9791666666666666"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M11.456249999999999 11.1625a5.64 5.64 0 0 0 3.7697916666666664 1.5666666666666667 6.854166666666666 6.854166666666666 0 0 0 2.29125 -0.381875"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
        </g>
    </svg>
</template>
