import { defineStore } from 'pinia';
import api from '@/plugins/axios.js';

export const useSSLStore = defineStore({
    id: 'ssl',
    state: () => ({
        sslProducts: [],
    }),
    getters: {
        sslCertificates: (state) => state.sslProducts?.data ?? [],
        totalSSLCertificates: (state) => state.sslProducts?.meta?.total ?? 0,
    },
    actions: {
        async hydrate() {
            try {
                await this.getSSLCertificates();
            } catch (error) {
                /* this error happens when the team registration is not finished */
                if (error.response?.status !== 409) {
                    console.log(error);
                }
            }
        },
        dehydrate() {
            this.clear();
        },
        clear() {
            this.sslProducts = null;
        },
        async getSSLCertificates(params = {}) {
            try {
                const response = await api.get(`/general/ssl-certificates`, { params });

                if (!response?.data?.data) {
                    return;
                }

                const certificates = [];

                for (let certificate of response.data.data) {
                    let sslProduct = response.data.included.find(
                        (p) => p.id === certificate.relationships.product.data.id
                    );
                    certificates.push({ ...certificate, product: sslProduct });
                }

                this.sslProducts = { ...response.data, data: certificates };
            } catch (error) {
                this.sslProducts = [];
            }
        },
        async getSSLCertificate(certificate) {
            return await api.get(`/general/ssl-certificates/${certificate}`);
        },
        async getSSLCertificateStatus(certificate) {
            return await api.get(`/general/ssl-certificates/${certificate}/request-status`);
        },
        async downloadSSLCertificate(certificate) {
            return await api.get(`/general/ssl-certificates/${certificate}/download`, {
                responseType: 'blob',
            });
        },
        async renewSSLCertificate(certificate) {
            return await api.post(`/general/ssl-certificates/${certificate}/renew`);
        },
        async revokeSSLCertificate(certificate) {
            return await api.get(`/general/ssl-certificates/${certificate}/revoke`);
        },
        async resendApprovalEmailSSLCertificate(certificate) {
            return await api.get(`/general/ssl-certificates/${certificate}/resend-approval-email`);
        },
        async getApprovalListSSLCertificate(certificate) {
            return await api.get(`/general/ssl-certificates/${certificate}/approval-list`);
        },
    },
});
