import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from '@/App.vue';
import router from '@/router';
import './assets/css/app.css';
import 'vue-toastification/dist/index.css';
import i18n from '@/utils/i18n.js';
import '@/plugins/day-js.js';

import Toast from 'vue-toastification';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import CFToastCloseButton from '@/components/CFToastCloseButton.vue';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: import.meta.env.MODE,
    appVersion: import.meta.env.VITE_APP_VERSION,
    autoTrackSessions: false,
});

const app = createApp(App);

app.use(Bugsnag.getPlugin('vue'));
app.use(i18n);
app.use(createPinia());

app.use(FloatingVue, {
    autoHideOnMousedown: false,
    themes: {
        'tooltip': {
            // Stops tooltip closing when clicking on the trigger
            hideTriggers: (events) => events,
            delay: {
                show: 100,
                hide: 0,
            },
        },
        'light-tooltip': {
            $extend: 'tooltip',
        },
        'dark-tooltip': {
            $extend: 'tooltip',
        },
    },
});

app.use(Toast, {
    position: 'bottom-right',
    toastClassName: 'cyberfusion-toast',
    toastBodyClassName: 'cyberfusion-toast-body',
    hideProgressBar: true,
    showCloseButtonOnHover: true,
    closeButton: CFToastCloseButton,
    closeOnClick: false,
    draggable: false,
    timeout: 3000,
    transition: 'Vue-Toastification__fade',
});

app.use(router);

app.mount('#app');
