import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import api from '@/plugins/axios';

export const useUserStore = defineStore('user', () => {
    const user = ref(null);
    const profilePicture = ref('');

    const language = computed(() => user.value?.attributes?.language);
    const isVerified = computed(() => !!user.value?.attributes?.email_verified);
    const hasPendingChange = computed(() => !!user.value?.attributes?.email_change_pending);
    const has2FA = computed(() => !!user.value?.attributes?.two_factor_enabled);
    const firstName = computed(() => user.value?.attributes?.first_name);
    const fullName = computed(() => `${firstName.value} ${user.value?.attributes?.last_name}`);

    const resendVerifyEmail = () => {
        return api.get('/general/verifications/user');
    };

    const getProfilePicture = async () => {
        if (!user.value) {
            return;
        }

        try {
            const response = await api.get(`/general/users/${user.value.id}/profile-picture`, {
                responseType: 'blob',
            });

            if (response.status !== 200) {
                return;
            }

            const urlCreator = window.URL || window.webkitURL;
            profilePicture.value = urlCreator.createObjectURL(response.data);
        } catch (e) {
            // Error retrieving profile picture
            // no need to tell user, they can continue
            // and use default profile picture.
        }
    };

    const hydrate = async () => {
        const { data } = await api.get('/general/profile');

        user.value = data.data;

        await getProfilePicture();
    };

    const dehydrate = async () => {
        user.value = null;
        profilePicture.value = '';
    };

    return {
        user,
        profilePicture,
        language,
        isVerified,
        hasPendingChange,
        has2FA,
        firstName,
        fullName,
        resendVerifyEmail,
        getProfilePicture,
        hydrate,
        dehydrate,
    };
});
