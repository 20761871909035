<script setup>
import { ref } from 'vue';

defineEmits(['update:modelValue']);

defineProps({
    name: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: 'text',
    },
    placeholder: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    modelValue: {
        type: String,
        default: null,
    },
    errors: {
        type: [Number, String, Boolean],
        default: null,
    },
    pattern: {
        type: String,
        default: null,
    },
    inputMode: {
        type: String,
        default: null,
    },
});

const inputRef = ref(null);

const focus = () => {
    inputRef.value?.focus();
};

defineExpose({
    focus,
});
</script>

<template>
    <div class="relative mb-8">
        <label
            :for="name"
            class="sr-only"
        >
            {{ placeholder }}
        </label>
        <input
            ref="inputRef"
            v-bind="$attrs"
            class="block w-full border-0 border-b bg-transparent px-0 leading-10 placeholder-primary/40 focus:border-primary focus:bg-transparent focus:outline-none focus:ring-transparent lg:text-2xl xl:text-3xl"
            :class="[!!errors ? 'border-warning' : 'border-primary/60']"
            :name="name"
            :type="type"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :value="modelValue"
            :pattern="pattern"
            :inputmode="inputMode"
            @input="$emit('update:modelValue', $event.target.value)"
        />
    </div>
</template>
