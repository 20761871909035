import { useAppStore } from '@/stores/app.js';
import { useUserStore } from '@/stores/user.js';
import { useRequestsStore } from '@/stores/requests.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { setLanguage } from '@/utils/i18n.js';
import { useDomainsStore } from '@/stores/domains.js';
import { useSupportStore } from '@/stores/support.js';
import { useTicketStore } from '@/stores/ticket.js';
import { useSSLStore } from '@/stores/ssl.js';
import { useTeamStore } from '@/stores/team.js';
import { usePermissionsStore } from '@/stores/permissions.js';

export function useStores(
    stores = [
        useUserStore,
        useRequestsStore,
        useNotificationsStore,
        useDomainsStore,
        useSSLStore,
        useTeamStore,
        useSupportStore,
        usePermissionsStore,
        useTicketStore,
    ]
) {
    return stores.map((useStore) => useStore());
}

export async function hydrate() {
    const stores = useStores();

    const appStore = useAppStore();
    const userStore = useUserStore();
    const teamStore = useTeamStore();
    const permissionsStore = usePermissionsStore();

    if (appStore.hydrated || appStore.hydrating) {
        return;
    }

    appStore.hydrating = true;

    try {
        /**
         * Multiple stores rely on the userStore, teamStore, and permissionsStore to be set,
         * so they can fetch user specific data. The following makes sure that these stores
         * are always fetched first, before we hydrate anything else.
         */
        await userStore.hydrate();
        await teamStore.hydrate();
        await permissionsStore.hydrate();

        if (
            userStore.user &&
            userStore.isVerified &&
            teamStore.hasRequiredInformation &&
            teamStore.isVerified &&
            teamStore.isUnlocked &&
            (teamStore.enforces2FA ? userStore.has2FA : true)
        ) {
            const hydratedStores = ['user', 'permissions'];
            await Promise.all(
                stores
                    .filter(({ $id }) => !hydratedStores.includes($id))
                    .map((store) => store.hydrate?.())
            );
        }

        await setLanguage();
    } catch (error) {
        appStore.error = error;
    } finally {
        appStore.hydrating = false;
    }

    appStore.hydrated = true;
}

export async function dehydrate(stores = useStores()) {
    const appStore = useAppStore();

    if (appStore.hydrated === false) {
        return;
    }

    for (const store of stores) {
        await store.dehydrate?.();
    }

    appStore.hydrated = false;
}
