<script setup>
import { DocumentTextIcon, GlobeEuropeAfricaIcon, UserIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import CFSSLLockIcon from '@/components/Icons/CFSSLLockIcon.vue';
import CFMarkSubstring from '@/components/Search/CFMarkSubstring.vue';

const props = defineProps({
    result: {
        type: Object,
        required: true,
    },
    isActive: {
        type: Boolean,
        default: false,
    },
    query: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['select']);

const icon = computed(() => {
    switch (props.result.item.type) {
        case 'domain':
            return GlobeEuropeAfricaIcon;
        case 'certificate':
            return CFSSLLockIcon;
        case 'contact':
            return UserIcon;
        default:
            return DocumentTextIcon;
    }
});
</script>

<template>
    <button
        type="button"
        class="search-result-single relative flex w-full items-center gap-x-4 py-6 text-left"
        :class="{
            'is-active': isActive,
        }"
        @click="emit('select', result.item)"
    >
        <span
            class="flex h-8 w-8 items-center justify-center rounded-full bg-primary text-secondary"
        >
            <component
                :is="icon"
                class="h-5 w-5"
            />
        </span>
        <CFMarkSubstring
            :value="result.item.label"
            :query="query"
        />
        <span
            class="ml-auto inline-flex w-24 shrink-0 items-center justify-center bg-gray-100 px-3 py-1 text-xs text-gray-800"
        >
            {{ $t(`search.types.${result.item.type}`) }}
        </span>
    </button>
</template>

<style scoped>
.search-result-single::after {
    @apply absolute inset-0 top-4 block origin-top-right translate-x-0 translate-y-0 rotate-0 skew-x-0 skew-y-0 scale-x-0 scale-y-100 transform border-b border-current transition-transform duration-300;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    content: '';
}

.search-result-single.is-active::after {
    @apply origin-top-left translate-x-0 translate-y-0 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 transform;
}
</style>
