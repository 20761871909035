<template>
    <svg
        width="24"
        height="24"
        viewBox="-0.25 -0.25 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.385416666666666 2.4479166666666665h10.770833333333332a0.9791666666666666 0.9791666666666666 0 0 1 0.9791666666666666 0.9791666666666666v0.9791666666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M19.09375 4.40625v-2.9375a0.9791666666666666 0.9791666666666666 0 0 0 -0.9791666666666666 -0.9791666666666666h-12.729166666666666a1.9583333333333333 1.9583333333333333 0 0 0 0 3.9166666666666665h13.708333333333332a0.9791666666666666 0.9791666666666666 0 0 1 0.9791666666666666 0.9791666666666666v16.645833333333332a0.9791666666666666 0.9791666666666666 0 0 1 -0.9791666666666666 0.9791666666666666h-13.708333333333332a1.9583333333333333 1.9583333333333333 0 0 1 -1.9583333333333333 -1.9583333333333333v-18.604166666666664"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M17.135416666666664 18.329020833333335s-1.9779166666666665 -0.8743958333333333 -3.3604999999999996 -1.3855208333333333c-0.9067083333333333 -0.3368333333333333 -0.7774583333333334 -1.8672708333333332 -0.252625 -2.442041666666667a4.193770833333334 4.193770833333334 0 0 0 1.2327708333333331 -3.4574375A2.9375 2.9375 0 0 0 11.75 7.833333333333333a2.9375 2.9375 0 0 0 -3.0060416666666665 3.2106874999999997 4.193770833333334 4.193770833333334 0 0 0 1.2327708333333331 3.4574375c0.5248333333333334 0.5747708333333332 0.6540833333333333 2.105208333333333 -0.252625 2.442041666666667 -1.3825833333333333 0.5121041666666667 -3.3595208333333333 1.3855208333333333 -3.3595208333333333 1.3855208333333333"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
