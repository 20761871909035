<script setup>
import { XCircleIcon } from '@heroicons/vue/24/outline';

import { computed } from 'vue';

const props = defineProps({
    color: {
        type: String,
        default: null,
    },
    dismissible: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['close']);

const backgroundClass = computed(() => {
    const color = props.color;

    if (!color) {
        return 'border-black text-black';
    }

    return {
        'border-accent text-accent': color === 'accent' || color === 'pending',
        'border-lime-500 text-white': color === 'success' || color === 'active',
        'border-warning text-warning': color === 'warning',
    };
});
</script>

<template>
    <div
        class="flex h-auto items-center border bg-transparent p-3"
        :class="backgroundClass"
    >
        <div class="ml-3">
            <p class="text-sm">
                <slot />
            </p>
        </div>
        <div
            v-if="dismissible"
            class="ml-auto pl-3"
        >
            <div class="-mx-1.5 -my-1.5">
                <button
                    type="button"
                    class="inline-flex p-1.5"
                    @click="emits('close')"
                >
                    <span class="sr-only">{{ $t('button.close') }}</span>
                    <XCircleIcon
                        class="h-5 w-5"
                        aria-hidden="true"
                    />
                </button>
            </div>
        </div>
    </div>
</template>
