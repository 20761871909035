<script setup>
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import { generatePassword } from '@/utils/utils';
import CFInputErrorWithCondition from '@/components/CFInputErrorWithCondition.vue';
import CFInputEyeToggle from '@/components/CFInputEyeToggle.vue';
import { reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    name: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: 'password',
    },
    placeholder: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    modelValue: {
        type: String,
        default: null,
    },
    pattern: {
        type: String,
        default: null,
    },
    inputMode: {
        type: String,
        default: null,
    },
    errors: {
        type: Number,
        default: null,
    },
    showGenerate: {
        type: Boolean,
        default: false,
    },
    showErrors: {
        type: Boolean,
        default: false,
    },
});

const { t } = useI18n();
const inputType = ref(props.type);

const form = reactive({
    password: props.modelValue,
});

const v$ = useVuelidate(
    {
        password: {
            required,
            minLength: helpers.withMessage(() => t('form.errors.minimal_length', 8), minLength(8)),
            containsUppercase: helpers.withMessage(
                () => t('form.errors.required_uppercase', 1),
                function (value) {
                    return /[A-Z]/.test(value);
                }
            ),
            containsLowercase: helpers.withMessage(
                () => t('form.errors.required_lowercase', 1),
                function (value) {
                    return /[a-z]/.test(value);
                }
            ),
            containsNumber: helpers.withMessage(
                () => t('form.errors.required_number', 1),
                function (value) {
                    return /[0-9]/.test(value);
                }
            ),
        },
    },
    form
);

const generateAPassword = () => {
    form.password = generatePassword();
    emit('update:modelValue', form.password);
    v$.value.password.$touch();
};

const showPassword = () => {
    inputType.value = inputType.value === 'password' ? 'text' : 'password';
};
</script>

<template>
    <div
        class="relative text-primary"
        :class="{ 'mb-8': !showErrors }"
    >
        <label
            :for="name"
            class="sr-only"
        >
            {{ placeholder }}
        </label>
        <input
            v-model="form.password"
            class="block w-full border-0 border-b bg-transparent pl-0 leading-10 placeholder-primary/40 focus:border-primary focus:bg-transparent focus:outline-none focus:ring-transparent lg:text-2xl xl:text-3xl"
            :class="[
                v$.password.$errors.length || errors ? 'border-warning' : 'border-primary/60',
                showGenerate ? 'pr-44' : 'pr-6',
            ]"
            :name="name"
            :type="inputType"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :pattern="pattern"
            :inputmode="inputMode"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <button
            v-if="showGenerate"
            type="button"
            class="absolute inset-y-0 right-9 flex items-center text-xs underline underline-offset-3"
            @click="generateAPassword"
        >
            {{ t('general.generate_password') }}
        </button>

        <CFInputEyeToggle
            :condition="inputType === 'password'"
            class="stroke-primary"
            @on-click="showPassword"
        />
    </div>
    <div
        v-if="showErrors"
        class="mb-8 mt-3 grid grid-cols-2 gap-x-2"
    >
        <CFInputErrorWithCondition
            :condition="v$.password.required.$invalid || v$.password.minLength.$invalid"
            :message="v$.password.minLength.$message"
        />
        <CFInputErrorWithCondition
            :condition="v$.password.containsUppercase.$invalid"
            :message="v$.password.containsUppercase.$message"
        />
        <CFInputErrorWithCondition
            :condition="v$.password.containsLowercase.$invalid"
            :message="v$.password.containsLowercase.$message"
        />
        <CFInputErrorWithCondition
            :condition="v$.password.containsNumber.$invalid"
            :message="v$.password.containsNumber.$message"
        />
    </div>
</template>
