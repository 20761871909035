<template>
    <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m23.5 12 -12 0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
        <path
            d="m19.5 16 4 -4 -4 -4"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
        <path
            d="M9.5 2.5H17a0.5 0.5 0 0 1 0.5 0.5v2.5"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
        <path
            d="M17.5 18.5V21a0.5 0.5 0 0 1 -0.5 0.5H9.5"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
        <path
            d="M0.5 21.223a0.5 0.5 0 0 0 0.392 0.488l8 1.777A0.5 0.5 0 0 0 9.5 23V1a0.5 0.5 0 0 0 -0.608 -0.488l-8 1.778a0.5 0.5 0 0 0 -0.392 0.488Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
        <path
            d="M4.5 12a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
    </svg>
</template>
