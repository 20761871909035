<template>
    <svg
        viewBox="-0.5 -0.5 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.706666666666667 7.944583333333333a20.968333333333334 20.968333333333334 0 0 1 3.8333333333333335 3.555416666666667S17.585416666666667 17.729166666666668 11.5 17.729166666666668a9.899583333333334 9.899583333333334 0 0 1 -2.875 -0.4791666666666667"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M6.555 16.445A20.55625 20.55625 0 0 1 0.4791666666666667 11.5S5.414583333333334 5.270833333333334 11.5 5.270833333333334a11.01125 11.01125 0 0 1 4.945 1.284166666666667"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M15.333333333333334 11.5a3.8333333333333335 3.8333333333333335 0 0 1 -3.8333333333333335 3.8333333333333335"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m20.84375 2.15625 -18.6875 18.6875"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M8.787916666666668 14.212083333333334a3.8333333333333335 3.8333333333333335 0 0 1 5.424166666666667 -5.424166666666667"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
