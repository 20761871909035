import api from '@/plugins/axios.js';

export const useAttachments = () => {
    const storeAttachment = (ticketIdentifier, replyId, file) => {
        const formData = new FormData();
        formData.append('file', file);

        return api.post(
            `/general/support-messages/${ticketIdentifier}/replies/${replyId}/attachments`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );
    };

    const deleteAttachment = async (ticketIdentifier, attachmentId) => {
        try {
            await api.delete(
                `/general/support-messages/${ticketIdentifier}/attachments/${attachmentId}`
            );
        } catch (e) {
            console.error(e);
            return false;
        }

        return true;
    };

    return {
        storeAttachment,
        deleteAttachment,
    };
};
