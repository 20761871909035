<template>
    <svg
        width="24"
        height="24"
        viewBox="-0.25 -0.25 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.858083333333332 12.239583333333332h9.791666666666666s0.4895833333333333 0 0.4895833333333333 0.4895833333333333v6.854166666666666s0 0.4895833333333333 -0.4895833333333333 0.4895833333333333h-9.791666666666666s-0.4895833333333333 0 -0.4895833333333333 -0.4895833333333333v-6.854166666666666s0 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m6.368499999999999 14.197916666666666 10.770833333333332 0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m9.306 12.239583333333332 0 7.833333333333333"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m12.2435 12.239583333333332 0 7.833333333333333"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M20.072916666666664 22.03125a0.9791666666666666 0.9791666666666666 0 0 1 -0.9791666666666666 0.9791666666666666h-14.6875a0.9791666666666666 0.9791666666666666 0 0 1 -0.9791666666666666 -0.9791666666666666v-20.5625a0.9791666666666666 0.9791666666666666 0 0 1 0.9791666666666666 -0.9791666666666666h8.8125l6.854166666666666 6.854166666666666Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M13.21875 0.4895833333333333v5.875a0.9791666666666666 0.9791666666666666 0 0 0 0.9791666666666666 0.9791666666666666h5.875"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m8.326833333333333 8.322916666666666 0 0.9791666666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m8.326833333333333 3.427083333333333 0 0.9791666666666666"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M6.364583333333333 8.322916666666666h2.9375a0.9791666666666666 0.9791666666666666 0 0 0 0 -1.9583333333333333h-1.9583333333333333a0.9791666666666666 0.9791666666666666 0 0 1 0 -1.9583333333333333h2.9375"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
