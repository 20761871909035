<template>
    <svg
        viewBox="-0.75 -0.75 24 24"
        xmlns="http://www.w3.org/2000/svg"
        height="18"
        width="18"
    >
        <path
            d="M9.886875 2.285625a1.8346875 1.8346875 0 0 0 2.72625 0L13.5 1.3125a1.8365625 1.8365625 0 0 1 3.1968750000000004 1.3246875l-0.0665625 1.3125a1.835625 1.835625 0 0 0 1.9228125 1.925625l1.3125 -0.0665625a1.8365625 1.8365625 0 0 1 1.321875 3.1968750000000004l-0.976875 0.88125a1.8375 1.8375 0 0 0 0 2.7271875l0.976875 0.88125a1.8365625 1.8365625 0 0 1 -1.3246875 3.1968750000000004l-1.3125 -0.0665625a1.835625 1.835625 0 0 0 -1.9275 1.9275l0.0665625 1.3125A1.8365625 1.8365625 0 0 1 13.5 21.1875l-0.8821874999999999 -0.9759374999999999a1.8365625 1.8365625 0 0 0 -2.72625 0L9.005625 21.1875A1.8365625 1.8365625 0 0 1 5.8125 19.8675l0.06749999999999999 -1.3125a1.835625 1.835625 0 0 0 -1.9275 -1.9275l-1.3125 0.0665625A1.835625 1.835625 0 0 1 1.3125 13.5l0.9759374999999999 -0.88125a1.8375 1.8375 0 0 0 0 -2.7271875L1.3125 9.005625A1.835625 1.835625 0 0 1 2.6334375000000003 5.8125l1.3125 0.0665625a1.835625 1.835625 0 0 0 1.9293749999999998 -1.9312500000000001L5.8125 2.634375A1.8365625 1.8365625 0 0 1 9.005625 1.3125Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
        <path
            d="M7.03125 11.2509375a4.21875 4.21875 0 1 0 8.4375 0 4.21875 4.21875 0 1 0 -8.4375 0Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
    </svg>
</template>
